import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://LMT-CDN-ENDPOINT-PROD.azureedge.net',
  serviceEndPoints: [
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservices.microsoft.com/lmt-coreapi',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservices.microsoft.com/lmt-draftapi',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservices.microsoft.com/lmt-uxapi',
    },
    {
      clientId: '83902542-ffa9-4272-9d09-31b93eccabfa',
      serviceEndPoint: 'https://professionalservices.microsoft.com/projectmanagement/api/v2.0',
    },
    {
      clientId: '83902542-ffa9-4272-9d09-31b93eccabfa',
      serviceEndPoint: 'https://professionalservices.microsoft.com/projectmanagement/api/v1.0',
    },
    {
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
      serviceEndPoint: 'https://oneprofileprodapipack.azurefd.net/',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp.microsoft.com/api/v1/Search/',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservices.microsoft.com/ati-coreapi-prod',
    },
    {
      clientId: 'bc72c2f2-9689-42a0-aa49-f579509c867b',
      serviceEndPoint: 'https://esxp.microsoft.com/rmavl/',
    },
    {
      clientId: 'https://microsoftsales.crm.dynamics.com',
      serviceEndPoint: 'https://microsoftsales.crm.dynamics.com/api/data',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservices.microsoft.com/us-coreapi',
    },
  ],
};
