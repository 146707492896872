import {
  FxpStateTransitionService,
  IAppService,
  IServiceEndPoints,
  IStateConfig,
  IRouteInfo,
  IPartnerBundle,
  IFxpAppContext,
  IAppServiceWithSharedComponent,
  ISharedComponents,
  ISharedComponentConfig
} from '@fxp/fxpservices';
import { StateParams } from '@uirouter/core';
import { TMStates, TMUrl } from './app/shared/constants/routes.const';
import { BehaviorSubject } from 'rxjs';
import { Inject, forwardRef } from '@angular/core';
import { appSettings } from './environments/common/appsettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class TimeExperienceApp_routes implements IAppService, IAppServiceWithSharedComponent {
  getSharedComponentsInfo(): ISharedComponents {
    const McsBulkSharedViewComp: ISharedComponentConfig = {
      componentDescription: 'This component renders bulk view of Time Submission',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'McsBulkTimeComponent'
    };

    const WeekViewSharedViewComp: ISharedComponentConfig = {
      componentDescription: 'This component renders labor weekview',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'WeekViewComponent'
    };

    const TimeLaborTileComp: ISharedComponentConfig = {
      componentDescription: 'This component is used to enter time in R360',
      componentInputs: ['userAlias', 'Alias of the user to enter time'],
      componentOutputs: [],
      componentName: 'labortilecomponent'
    };

    return {
      appName: 'timemanagement',
      components: [McsBulkSharedViewComp, WeekViewSharedViewComp, TimeLaborTileComp],
      disableSharing: false,
      sharedBundles: ['timemanagement-Bundle']
    };
  }
  getRoutes(userContext: IFxpAppContext): IRouteInfo {
    const tmDashboard: IStateConfig = {
      name: TMStates.MCS,
      url: TMUrl.MCS,
      params: new StateParams({ actionClicked: '', fromRoute: '' }),
      data: {
        headerName: 'Labor Entry - Assignments',
        breadcrumbText: 'Labor Entry - Assignments',
        pageTitle: 'Labor Entry - Assignments',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-McsAssignments'
      }
    };

    const tmBulkDashboard: IStateConfig = {
      name: TMStates.MCSBULK,
      url: TMUrl.MCSBULK,
      data: {
        headerName: 'MCS Bulk',
        breadcrumbText: 'MCS Bulk',
        pageTitle: 'MCS Bulk',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'ActivityInsights'
      }
    };

    const TimeHubDashboard: IStateConfig = {
      name: 'time.timehub',
      url: '/timeentry',
      data: {
        headerName: 'Labor Entry',
        breadcrumbText: 'Labor Entry',
        pageTitle: 'Labor Entry - Day View',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-DayView'
      },
      params: new StateParams({
        isOboEnabled: false,
        alias: '',
        displayName: '',
        currentUserData: '',
        actionClicked: '',
        fromRoute: ''
      })
    };
    const WeeklyViewDashboard: IStateConfig = {
      name: 'time.weeklyview',
      url: '/weekview',
      data: {
        headerName: 'Week View',
        breadcrumbText: 'Week View',
        pageTitle: 'Labor Entry - Week View',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-WeekView'
      },
      params: new StateParams({
        isOboEnabled: false,
        alias: '',
        displayName: '',
        currentUserData: ''
      })
    };
    const ParentDashboard: IStateConfig = {
      name: 'time',
      url: '/time',
      params: new StateParams({
        isOboEnabled: false,
        alias: '',
        displayName: '',
        currentUserData: ''
      }),
      data: {
        headerName: 'Labor Entry',
        breadcrumbText: 'Labor Entry',
        pageTitle: 'Labor Entry',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people'
      }
    };

    const OBO: IStateConfig = {
      name: TMStates.OBO,
      url: TMUrl.OBO,
      data: {
        headerName: 'Administration',
        breadcrumbText: 'Labor Entry - Act on Behalf Of',
        pageTitle: 'Labor Entry - Act on Behalf Of',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-OBO'
      }
    };

    const NonProject: IStateConfig = {
      name: TMStates.NON_PROJECT,
      url: TMUrl.NON_PROJECT,
      params: new StateParams({ actionClicked: '', fromRoute: '' }),
      data: {
        headerName: 'Labor Entry - Non Project',
        breadcrumbText: 'Labor Entry - Non Project',
        pageTitle: 'Labor Entry - Non Project',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-NonProject'
      }
    };
    const GeneralAdmin: IStateConfig = {
      name: TMStates.GENERAL_ADMIN,
      url: TMUrl.GENERAL_ADMIN,
      params: new StateParams({ actionClicked: '', fromRoute: '' }),
      data: {
        headerName: 'Labor Entry - Service-Specific, Non-Billable Work',
        breadcrumbText: 'Labor Entry - Service-Specific, Non-Billable Work',
        pageTitle: 'Labor Entry - Service-Specific, Non-Billable Work',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-GeneralAdmin'
      }
    };

    const Axis: IStateConfig = {
      name: TMStates.AXIS,
      url: TMUrl.AXIS,
      params: new StateParams({ actionClicked: '', fromRoute: '' }),
      data: {
        headerName: 'Labor Entry - Axis',
        breadcrumbText: 'Labor Entry - Axis',
        pageTitle: 'Labor Entry - Axis',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-Axis'
      }
    };

    const CsNonBillable: IStateConfig = {
      name: TMStates.CSNONBILLABLE,
      url: TMUrl.CSNONBILLABLE,
      params: new StateParams({ actionClicked: '', fromRoute: '' }),
      data: {
        headerName: 'Opportunities',
        breadcrumbText: 'Opportunities',
        pageTitle: 'Opportunities',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-SuccessEngagements'
      }
    };

    const TimeApprovalDashboard: IStateConfig = {
      name: TMStates.Approval,
      url: TMUrl.APPROVAL,
      data: {
        headerName: 'Labor Approval',
        breadcrumbText: 'Labor Approval',
        pageTitle: 'Labor Approval',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborApproval-Summary'
      }
    };
    const TimeApprovalHistory: IStateConfig = {
      name: TMStates.APPROVAL_HISTORY,
      url: TMUrl.APPROVAL_HISTORY,
      data: {
        headerName: 'Approval History',
        breadcrumbText: 'Approval History',
        pageTitle: 'Approval History',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborApproval-History'
      }
    };
    const ActivityDashboard: IStateConfig = {
      name: TMStates.ACTIVITY,
      url: TMUrl.ACTIVITY,
      data: {
        headerName: 'Activity Insights',
        breadcrumbText: 'Activity Insights',
        pageTitle: 'Activity Insights',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        ocvAreaName: 'GCE_Activity',
        pageNameForTelemetry: 'ActivityInsights'
      }
    };
    const TimePackagesDashboard: IStateConfig = {
      name: TMStates.PREMIER,
      url: TMUrl.PREMIER,
      params: new StateParams({ actionClicked: '', fromRoute: '' }),
      data: {
        headerName: 'Labor Entry - Packages',
        breadcrumbText: 'Labor Entry - Packages',
        pageTitle: 'Labor Entry - Packages',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people',
        pageNameForTelemetry: 'LaborEntry-Packages'
      }
    };

    const Unauthorize: IStateConfig = {
      name: TMStates.UNAUTHORIZE,
      url: TMUrl.UNAUTHORIZE,
      params: new StateParams({ fromRoute: '' }),
      data: {
        headerName: 'Unauthorize',
        breadcrumbText: 'Unauthorize',
        pageTitle: 'Unauthorize',
        partnerTelemetryName: 'LaborManagement',
        style: 'icon icon-people'
      }
    };

    const routeInfo: IRouteInfo = {
      applicationName: 'timemanagement',
      sharedBundles: ['timemanagement-Bundle'],
      routes: [
        ParentDashboard,
        tmDashboard,
        tmBulkDashboard,
        TimeHubDashboard,
        TimePackagesDashboard,
        OBO,
        NonProject,
        GeneralAdmin,
        Axis,
        CsNonBillable,
        TimeApprovalDashboard,
        Unauthorize,
        WeeklyViewDashboard,
        ActivityDashboard,
        TimeApprovalHistory
      ]
    };
    return routeInfo;
  }

  public getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  public getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    let bundlesData = [
      {
        name: 'timemanagement-Bundle',
        files: [
          `${baseUrl}/vendor.bundle.js`,
          `${baseUrl}/styles.bundle.js`,
          `${baseUrl}/main.bundle.js`
        ],
        sequentialLoading: true
      }
    ];
    return bundlesData;
  }
}

PartnerAppRegistrationService.registerLazyLoadedApp(TimeExperienceApp_routes);
