export enum TMStates {
  MCS = 'time.mcstimemanagement',
  MCSBULK = 'time.mcsbulktimemanagement',
  HUB = 'time.timehub',
  WEEKLYVIEW = 'time.weeklyview',
  OBO = 'timemangementobo',
  NON_PROJECT = 'time.nonproject',
  PARENT = 'time',
  PREMIER = 'time.packages',
  UNAUTHORIZE = 'timeunauthorize',
  Approval = 'timeapproval',
  GENERAL_ADMIN = 'time.generaladmin',
  AXIS = 'time.axis',
  CSNONBILLABLE = 'time.csnonbillable',
  ACTIVITY = 'activity',
  APPROVAL_HISTORY = 'time.approvalhistory',
  TALENT_WB = 'workbench',
  NUANCE = 'time.nuance'
}
export enum TMUrl {
  MCS = '/assignments/managetime',
  MCSBULK = '/assignments/bulkmanagetime',
  HUB = '/timehub',
  OBO = '/time/actonbehalf',
  PREMIER = '/packages/managetime',
  NON_PROJECT = '/nonproject/managetime',
  PARENT = '/time',
  UNAUTHORIZE = '/unauthorize',
  APPROVAL = '/timeapproval',
  GENERAL_ADMIN = '/generaladmin/managetime',
  AXIS = '/axis/managetime',
  CSNONBILLABLE = '/csnonbillable/managetime',
  ACTIVITY = '/activity?landing',
  APPROVAL_HISTORY = '/approvalhistory',
  TALENT_WB = 'workbench/DeliveryResource?tab=Labor',
  NUANCE = '/nuance/managetime'
}
