import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const aurorasit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://LMT-CDN-ENDPOINT-DEV.azureedge.net',
  serviceEndPoints: [
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/lmt-coreapi-dev',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/lmt-draftlabor-dev',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://tm-web-core-dev-eastus.azurewebsites.net/',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://ps-sms-api-uat.azurewebsites.net/api/v1',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://ps-sms-api-int.azurewebsites.net/api/v1',
    },
    {
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/projectmanagementsit/api/v2.0',
    },
    {
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/projectmanagementsit/api/v1.0',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/time-core/api/v1',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/time-core/api/v1',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://tm-web-ux-uat-eastus.azurewebsites.net/',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://tm-web-ux-prod-eastus.azurewebsites.net/',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/',
    },
    {
      clientId: '71a156e9-6196-4afe-aa95-4ca2a00790ca',
      serviceEndPoint: 'https://oneprofilesitapipack-aurora.azurewebsites.net/api/v1',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/lmt-UXapi-dev/',
    },
    {
      clientId: 'https://msxuat.crm.dynamics.com',
      serviceEndPoint: 'https://msxuat.crm.dynamics.com/api/data',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/ati-coreapi-dev',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://availabilityuat.azurefd.net',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/us-coreapi-dev',
    },
  ],
};
